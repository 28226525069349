import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")
import debounce from 'lodash/debounce'
import urls from './urls'
import handleEscape from './handle_escape'
import { snakeCaseKeys, defaultLineItem, lineItemTypeDisplay } from './utils'

Vue.filter('toCurrency', function (value) {
  if (! value) {
    return ''
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
  return formatter.format(value)
})

Vue.component('line-item-form', {
  mixins: [handleEscape],
  props: [
    'lineItem', 'idx', 'parentType'
  ],
  mounted() {
    this.updateDataFromProps()
    // vue-trix is mounted before the hidden field has a value; set it manually
    this.initialNotes = this.notes
    this.watchKeys()
    if (this.customMeshHeight != null) {
      this.meshHeight = 'custom'
    }
    // New estimates line items default to type cubicle curtain
    if (this.queue === 'projects' && this.id === null && this.dataChanged === false) {
      this.type = 'LineItem::CubicleCurtain'
    }
  },
  data() {
    return {...defaultLineItem, initialNotes: '', dataChanged: false, meshError: '', atsKitDesc: [] }
  },
  watch: {
    lineItem: {
      handler() {
        this.updateDataFromProps()
        this.initialNotes = this.notes
      },
      deep: true
    },
    railroaded(newVal) {
      if (newVal) {
        this.meshHeight = 'custom'
      }
    },
    // probably we're looking at a new line item, so override markup default here.
    // Only admins can edit markup, so for non-admin users, markup is handled server-side.
    type(newVal) {
      if (newVal === 'LineItem::Ripplefold') {
        if (this.fabricCompany === '') {
          this.hemHeight = 12
          this.fabricationPerWidthPrice = 96
        }
      }
      if (newVal === 'LineItem::RipplefoldDraperyTrack') {
        if (this.trackSizeA === '') {
          this.trackMarkupPercent = 10
        }
      }
      if (newVal === 'LineItem::Track') {
        if (this.trackSizeA === '') {
          this.trackMarkupPercent = -15
        }
      }
      if (newVal === 'LineItem::InterlocKit') {
        if (this.interlocKitTypeName === '') {
          this.trackMarkupPercent = -15
        }
      }
    },
    // clears unused track dimensions;
    // fixes issue with extra dimensions being passed for pricing
    trackStyle(newVal) {
      if(newVal != 'style_7') {
        this.trackSizeD = ''
      }
      if(newVal !== 'style_4' && newVal !== 'style_7') {
        this.trackSizeC = ''
      }
      if(newVal === 'style_1' || newVal === 'custom_flexible') {
        this.trackSizeB = ''
      }
      if(newVal === 'custom_flexible') {
        this.trackMaterial = 'flexible_track'
      }
    },
    trackMaterial(newVal) {
      if (newVal === 'no_track') {
        this.screwType = 'no_screws'
      }
    },
    split(newVal) {
      if(newVal && this.panels == 1) {
        this.panels = 2
      }
    },
    kissTheFloor(newVal) {
      if(this.handleChanges) {
        if(newVal && this.curtainLength) {
          this.curtainLength = (+this.curtainLength) - 2
        }
        else if(! newVal && this.curtainLength) {
          this.curtainLength = (+this.curtainLength) + 2
        }
      }
    },
    velcro(newVal) {
      if(newVal) {
        this.velcroToWall = false
      }
    },
    velcroToWall(newVal) {
      if(newVal) {
        this.velcro = false
      }
    },
    meshHeight(newVal) {
      if (newVal !== 'custom') {
        this.customHeight = null
      }
    },
    rrCombinedLength() {
      // this is only relevant for snap curtains & railroaded cubicle curtains
      if (this.finishedLength != this.rrCombinedLength) {
        this.meshError = 'Mesh + Fabric Length does not match Finished Length'
      } else {
        this.meshError = ''
      }
    },
    atsTrackKitTypeName(newVal) {
      // see if we have a kit description to show
      jQuery.ajax({
        method: 'POST',
        url: urls.lineItemKitDesc,
        data: { name: newVal },
        success: this.updateKitDesc.bind(this)
      })

      if (newVal && newVal.indexOf('Flexible') > -1) {
        this.trackColor = 'white'
      }
    },
    splitDraw(newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        if (newVal === 'right') {
          this.pinchPleatLeftReturn = this.pinchPleatLeftOverlap = 0
        } else if (newVal === 'left') {
          this.pinchPleatRightReturn = this.pinchPleatRightOverlap = 0
        }
      }
    },
    $data: {
      handler() {
        // Prevent extraneous price-fetching
        if(this.handleChanges) {
          this.$emit('disable', this.$data)
          this.dataChanged = true
          this.fetchPricing()
        }
      },
      deep: true
    }
  },
  computed: {
    showTrack() {
      return ['CurtainTrackValance', 'Track', 'InterlocTrack', 'CubicleCurtain', 'SnapCurtain', 'Ripplefold', 'RipplefoldDraperyTrack', 'PinchPleat'].includes(this.baseType)
    },
    showCurtain() {
      return this.baseType == 'CurtainTrackValance'
    },
    showValance() {
      return this.baseType == 'CurtainTrackValance'
    },
    showValanceOnly() {
      return this.baseType == 'Valance'
    },
    showFabric() {
      return ['CurtainTrackValance', 'Panel'].includes(this.baseType)
    },
    showPanel() {
      return this.baseType == 'Panel'
    },
    showCubicleCurtain() {
      return this.baseType == 'CubicleCurtain'
    },
    showSnapCurtain() {
      return this.baseType == 'SnapCurtain'
    },
    showRipplefold() {
      return this.baseType == 'Ripplefold'
    },
    showPinchPleat() {
      return this.baseType == 'PinchPleat'
    },
    showInterlocKit() {
      return this.baseType == 'InterlocKit'
    },
    showInterlocPanel() {
      return ['InterlocPanel', 'InterlocTrack'].includes(this.baseType)
    },
    showStrut() {
      return this.baseType == 'Strut'
    },
    // showVelcroPrice() {
    //   return this.baseType == 'CurtainTrackValance' || this.baseType == 'Panel'
    // },
    showRollershade() {
      return this.baseType == 'Rollershade'
    },
    showFabricOnly() {
      return this.baseType == 'Fabric'
    },
    showAtsKit() {
      return this.baseType == 'ATSKit'
    },
    showSampleKit() {
      return this.baseType == 'SampleKit'
    },
    showCustom() {
      return this.baseType == 'Custom'
    },
    showTrackPart() {
      return this.baseType == 'ATSTrackPart'
    },
    showTrackSizeB() {
      return this.trackStyle !== 'style_1' && this.trackStyle !== 'custom_flexible'
    },
    showTrackSizeC() {
      return this.trackStyle === 'style_4' || this.trackStyle === 'style_7'
    },
    showTrackSizeD() {
      return this.trackStyle === 'style_7'
    },
    typeFormatted() {
      return lineItemTypeDisplay[this.type]
    },
    baseType() {
      return this.type.replace('LineItem::', '')
    },
    curtainPriceHasOverride() {
      return (this.doOverrideCurtainPrice || this.overrideCurtainPrice != 0) ? 'overridden' : null
    },
    trackPriceHasOverride() {
      return (this.doOverrideTrackPrice || this.overrideTrackPrice != 0) ? 'overridden' : null
    },
    atsKitPriceHasOverride() {
      return (this.doOverrideATSKitPrice || this.overrideATSKitPrice != 0) ? 'overridden' : null
    },
    atsTrackPartPriceHasOverride() {
      return (this.doOverrideATSTrackPartPrice || this.overrideATSTrackPartPrice != 0) ? 'overridden' : null
    },
    velcroPriceHasOverride() {
      return (this.doOverrideVelcroPrice ||  this.overrideVelcroPrice != 0) ? 'overridden' : null
    },
    valancePriceHasOverride() {
      return (this.doOverrideValancePrice || this.overrideValancePrice != 0) ? 'overridden' : null
    },
    rollershadePriceHasOverride() {
      return (this.doOverrideRollershadePrice || this.overrideRollershadePrice != 0) ? 'overridden' : null
    },
    interlocPanelPriceHasOverride() {
      return (this.doOverrideInterlocPanelPrice || this.overrideInterlocPanelPrice != 0) ? 'overridden' : null
    },
    interlocKitPriceHasOverride() {
      return (this.doOverrideInterlocKitPrice || this.overrideInterlocKitPrice != 0) ? 'overridden' : null
    },
    strutPriceHasOverride() {
      return (this.doOverrideStrutPrice || this.overrideStrutPrice != 0) ? 'overridden' : null
    },
  },
  methods: {
    updateDataFromProps() {
      this.handleChanges = false
      for(var key in this.lineItem) {
        // Using this.$data to handle _destroy,
        // which is not proxied
        this.$data[key] = this.lineItem[key]
      }
      this.handleChangesNow()
    },
    save() {
      this.$emit('save', this.$data)
    },
    close() {
      if(this.canDiscard()) {
        this.discardItem()
      }
      else if(this.$data._destroy || this.$el.form.reportValidity()) {
        this.removeKeyListener()
        this.$emit('close')
      }
    },
    canDiscard() {
      return ! this.dataChanged && ! this.id
    },
    discardItem() {
      this.$emit('discard')
    },
    deleteItem() {
      this.$data._destroy = true
      this.save()
      this.close()
    },
    copyItem() {
      if (this.$el.form.reportValidity()) {
        this.save()
        this.$emit('copy')
        this.close()
      }
    },
    undelete() {
      this.$data._destroy = false
      this.save()
    },
    fetchPricing: debounce( function() {
      jQuery.ajax({
        method: 'POST',
        url: urls.lineItemPrice,
        data: { line_item: snakeCaseKeys(this.$data) },
        success: this.updatePricing.bind(this),
        failure: this.save.bind(this)
      })
    }, 300),
    updateNotes(str) {
      this.notes = str
    },
    updatePricing(response) {
      this.handleChanges = false
      Object.keys(response).forEach(function (key) {
        this[key] = response[key]
      }, this)
      this.save()
      this.handleChangesNow()
    },
    updateKitDesc(response) {
      this.atsKitDesc = response
    },
    updateTrackKitType(e) {
      let el = e.target
      this.atsTrackKitTypeName = el.selectedIndex > 0 ? el.options[el.selectedIndex].text : ''
    },
    updateTrackPartType(e) {
      let el = e.target
      this.atsTrackPartTypeName = el.selectedIndex > 0 ? el.options[el.selectedIndex].text : ''
    },
    updateStrutType(e) {
      let el = e.target
      this.strutTypeName = el.selectedIndex > 0 ? el.options[el.selectedIndex].text : ''
    },
    updateInterlocKitType(e) {
      let el = e.target
      this.interlocKitTypeName = el.selectedIndex > 0 ? el.options[el.selectedIndex].text : ''
    },
    isDraperyTrack() {
      return ['Ripplefold', 'RipplefoldDraperyTrack'].includes(this.baseType)
    },
    isPinchPleat() {
      return ['PinchPleat'].includes(this.baseType)
    },
    // includes DraperyTrack because this method is how the track partial decides if it should
    // show the markup %. If that becomes confusing, should refactor.
    isDrapery() {
      return ['Ripplefold', 'SnapCurtain', 'CubicleCurtain', 'RipplefoldDraperyTrack'].includes(this.baseType)
    },
    // it's possible we only need a showMarkup method that checks queue (like the method below this
    // one)
    showTrackMarkup() {
      return (this.isDrapery() || this.queue === 'projects')
    },
    showMarkup() {
      return (this.queue === 'projects')
    },
    isFlexibleKit() {
      if (! this.atsTrackKitTypeName) {
        return false
      }
      return this.atsTrackKitTypeName.indexOf('Flexible') > -1
    },
    resetData() {
      for (let key in defaultLineItem) {
        if (key != 'type') {
          this[key] = defaultLineItem[key]
        }
      }
    },
    atsKitDescList() {
      return this.atsKitDesc.map(item => `<li>${item}</li>`).join('')
    }
  }
})
