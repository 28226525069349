import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")
require("selectize/dist/js/standalone/selectize.js")
import customerSelectize from './customer_selectize'
import { defaultLineItem, snakeCaseKeys, formatCurrency, defaultWorkOrder, defaultShipment } from './utils'
import cloneDeep from 'lodash/cloneDeep'
import draggable from 'vuedraggable'
import urls from './urls'

Vue.component('order-form', {
  mixins: [customerSelectize],
  components: { draggable },
  props: [
  ],
  mounted() {
    var passedData = $(this.$el).data('orderJson')
    for(var key in passedData) {
      this[key] = passedData[key]
    }

    if($('#order_customer_id').length) {
      this.setupCustomerSelect()
    }
    if(! (this.shippingPriceOverrideFormAllowZero === null)) {
      this.showShippingOverride = true
    }

    this.$el.addEventListener('ajax:before', this.beforeSave.bind(this))
    this.$el.addEventListener('ajax:success', this.saveSuccess.bind(this))
    this.$el.addEventListener('ajax:complete', this.saveComplete.bind(this))

    // for testing:
    // this.addLineItem()
    Vue.nextTick( () => {
      new Foundation.Sticky($('.order_form_sidebar'))
      this.pageLoaded = true
    })
  },
  data() {
    return {
      pageLoaded: false,
      customerName: null,
      customerId: null,
      lineItems: [],
      workOrders: [],
      currentLineItem: {},
      currentLineItemIndex: null,
      currentWorkOrder: {},
      initialWorkOrders: {},
      currentWorkOrderIndex: null,
      currentEmail: null,
      openModal: null,
      currentEmailId: null,
      id: null,
      showExtraShipment: false,
      storeType: 'american_drapery_systems',
      saveNextAction: null,
      shippingPriceForm: 0,
      shippingPriceOverrideFormAllowZero: null,
      showShippingOverride: false,
      installationPriceForm: 0,
      taxPercent: 0,
      discountPercent: 0,
      saving: false,
      shipMethod: 'ground',
      shipments: [],
      sideMark: '',
      validateFields: false,
      payWithStripe: false,
      payMethod: 'stripe',
      allowPartialPayment: false,
      totalPaid: 0,
      workUsers: {},
      importedPrices: false,
      hasAddressInfo: false,
      totalPriceForm: 0,
      subtotalPriceForm: 0,
      creatingCustomer: false,
      saveBlocked: false,
      queue: '',
      estimatedShipDate: '',
    }
  },
  computed: {
    modalOpen() {
      return !! this.openModal
    },
    lineItemsTotalCalculated() {
      return this.lineItems.reduce((subtotal, item) => item.totalPrice + subtotal, 0)
    },
    subtotalCalculated() {
      if(this.importedPrices) {
        return +this.subtotalPriceForm
      }
      else {
        return (+this.lineItemsTotalCalculated)
      }
    },
    subtotalCalculatedFormatted() {
      return formatCurrency(this.subtotalCalculated)
    },
    totalCalculated() {
      if(this.importedPrices) {
        return this.totalPriceForm
      }
      else {
        let useShippingPrice = +this.shippingPriceForm
        if(!(this.shippingPriceOverrideFormAllowZero === null)) {
          useShippingPrice = +this.shippingPriceOverrideFormAllowZero
        }
        let subtotalWithDiscount = this.subtotalCalculated * (1 - this.discountPercent / 100.0)
        let preTax = subtotalWithDiscount + useShippingPrice + (+this.installationPriceForm)
        return preTax * (1 + this.taxPercent / 100.0)
      }
    },
    totalCalculatedFormatted() {
      return formatCurrency(this.totalCalculated)
    },
    readyForOrder() {
      return this.onlySamples || this.totalCalculated > 0
    },
    onlySamples() {
      return this.lineItems.length > 0 && this.lineItems.every((item) => item.type == 'LineItem::SampleKit')
    }
  },
  methods: {
    displayName(kind) {
      return this.customerName || `New ${kind}`
    },

    setCustomer(value, _) {
      this.customerId = value
      const customer = this.customerSelectize.sifter.items[value]
      if (customer) {
        this.customerName = customer.name
        // javascript not happy with literal including ?
        this.hasAddressInfo = customer['has_address_info?']
      }
    },

    showCustomerForm() {
      this.creatingCustomer = true
      Vue.nextTick( () => {
        document.getElementById('order_customer_attributes_name').focus()
      })
    },

    save(nextAction) {
      if(nextAction) {
        this.saveNextAction = nextAction
      }
      this.saving = true

      $(this.$el).find('button.save[type=submit]').click()
    },

    saveSuccess(event) {
      if(this.saveNextAction == 'no_reload') {
        // TODO not sure we'll need this, but this is how we'd handle save-in-place
        alert('Saved!')
      }
      else if(event.detail[2].getResponseHeader('location')) {
        window.location.href = event.detail[2].getResponseHeader('location')
      }
      else {
        location.reload()
      }
    },

    saveComplete() {
      this.saveNextAction = null
      this.saving = false
    },

    handleNotesChange(event) {
      this.notes = event.srcElement ? event.srcElement.value : event.target.value
    },

    // The base form is not validated until submit is called;
    // this allows us to validate just the line-item forms before closing them
    // otherwise eg the client_id field pops up a validation error underneath
    // the modal form
    enableValidation(_) {
      this.validateFields = true
      let form = this.$el
      if(form.reportValidity()) {
        form.dispatchEvent(new Event('submit', {bubbles: true}))
      }
      else {
        this.validateFields = false
      }
    },

    submitStripe() {
      this.save()
    },

    shippingOverrideClick() {
      this.showShippingOverride = true
      Vue.nextTick( () => {
        document.getElementById('order_shipping_price_override_form_allow_zero').focus()
      })
    },

    // Modals
    closeModal() {
      this.openModal = null
    },

    // Line items
    // =================================
    addLineItem() {
      this.lineItems.push({ ...defaultLineItem, queue: this.queue, orderId: this.id })
      this.openLineItem(this.lineItems.length - 1)
    },

    openLineItem(idx) {
      this.currentLineItemIndex = idx
      // Prevents circular updating
      this.currentLineItem = {...this.lineItems[idx]}
      this.openModal = 'lineItem'
      window.scrollTo(0,0)
    },

    saveLineItem(data) {
      // Splat prevents circular updating
      this.$set(this.lineItems, this.currentLineItemIndex, {...data})
      this.saveBlocked = false
    },

    closeLineItem() {
      this.closeModal()
      this.currentLineItemIndex = null
      window.scrollTo(0,0)
    },

    copyLineItem() {
      // The first few overrides apply across the board.
      // Josh wants copied Pinch Pleats to have their split draw & related #s reset on copy.
      this.lineItems.push({
        ...this.lineItems[this.currentLineItemIndex],
        id: null,
        dataChanged: true,
        orderId: this.id,
        splitDraw: '',
        pinchPleatLeftReturn: defaultLineItem['pinchPleatLeftReturn'],
        pinchPleatRightReturn: defaultLineItem['pinchPleatRightReturn'],
        pinchPleatRightOverlap: defaultLineItem['pinchPleatRightOverlap'],
        pinchPleatLeftOverlap: defaultLineItem['pinchPleatLeftOverlap']
      })
      this.closeLineItem()
    },

    discardLineItem() {
      this.lineItems.splice(this.currentLineItemIndex, 1)
      this.closeLineItem()
    },

    // Work Orders
    // =================================
    openInitialWorkOrders() {
      this.openModal = 'initialWorkOrders'
    },

    sendInitialOrders(data) {
      this.closeModal()
      this.initialWorkOrders = data
      this.save()
    },

    // ================================
    addWorkOrder() {
      this.workOrders.push(cloneDeep(defaultWorkOrder))
      this.openWorkOrder(this.workOrders.length - 1)
    },

    addTroubleTicket() {
      this.workOrders.push({...cloneDeep(defaultWorkOrder), troubleTicket: true})
      this.openWorkOrder(this.workOrders.length - 1)
    },

    openWorkOrder(idx) {
      this.currentWorkOrderIndex = idx
      // Prevents circular updating
      this.currentWorkOrder = {...this.workOrders[idx]}
      this.openModal = 'workOrder'
      window.scrollTo(0,0)
    },

    saveWorkOrder(data) {
      // Splat prevents circular updating
      this.$set(this.workOrders, this.currentWorkOrderIndex, {...data})
    },

    closeWorkOrder() {
      this.closeModal()
      this.currentWorkOrderIndex = null
      window.scrollTo(0,0)
    },

    discardWorkOrder() {
      this.WorkOrder.splice(this.currentWorkOrderIndex, 1)
      this.closeWorkOrder()
    },

    // Shipments
    // =================================
    getNewShipment() {
      let newShipment = cloneDeep(defaultShipment)
      newShipment.baseOrderId = this.id
      return newShipment
    },

    addLabel() {
      let newShipment = this.getNewShipment()
      newShipment.showParcel = true
      this.shipments.push(newShipment)
      Vue.nextTick( () => {
        document.querySelectorAll('.shipping .shipment')[this.shipments.length - 1].querySelector('.order_shipments_length').getElementsByTagName('input')[0].focus()
      })
    },

    addOneRateLabel() {
      let newShipment = this.getNewShipment()
      newShipment.showParcel = true
      newShipment.oneRate = true
      newShipment.oneRatePackageType = 'FedExEnvelope'
      this.shipments.push(newShipment)
      Vue.nextTick( () => {
        document.querySelectorAll('.shipping .shipment')[this.shipments.length - 1].querySelector('.order_shipments_weight').getElementsByTagName('input')[0].focus()
      })
    },

    addTracker() {
      let newShipment = this.getNewShipment()
      this.shipments.push(newShipment)
      Vue.nextTick( () => {
        document.querySelectorAll('.shipping .shipment')[this.shipments.length - 1].querySelector('.order_shipments_tracking_code').getElementsByTagName('input')[0].focus()
      })
    },

    // Email
    // =================================
    newQuoteEmail() {
      jQuery.ajax({
        method: 'POST',
        url: `/quotes/${this.id}/send_email.json`,
        success: function(data) {
          if (data.redirect) {
            window.location.replace(data.redirect)
          }
        }
      })
    },

    newOrderConfirmationEmail() {
      this.newEmail('order')
    },

    newSampleEmail() {
      this.newEmail('free_sample')
    },

    displayEmail(id) {
      this.currentEmailId = id
      jQuery.ajax({
        method: 'GET',
        url: urls.getEmail(id),
        success: this.openEmail.bind(this)
      })
    },

    newEmail(emailType) {
      if(this.currentEmail && ! this.currentEmail.sentAt) {
        this.openEmail()
      }
      jQuery.ajax({
        method: 'GET',
        url: urls.defaultEmail,
        data: {
          kind: emailType,
          'order[id]': this.id,
          'order[store_type]': this.storeType
        },
        success: this.openEmail.bind(this)
      })
    },

    openEmail(response) {
      if(response) {
        this.currentEmail = response
      }
      this.openModal = 'email'
      window.scrollTo(0,0)
    },
    
    saveEmail(data) {
      this.closeModal()
      this.currentEmail = data

      this.save()
    },

    closeEmail() {
      this.closeModal()
    },
    // =================================

    beforeSave() {
      this.validateFields = true
      this.serializeExtraAttributes()
      // validate
      let form = this.$el
      if(form.reportValidity()) {
        return true
      }
      else {
        this.validateFields = false
        return false
      }
    },

    serializeExtraAttributes() {
      let extraData = {
        line_items_attributes: this.lineItems.map( (item) => { return snakeCaseKeys(item) } ),
        work_orders_attributes: this.workOrders.map( (item) => { return snakeCaseKeys(item) } ),
        initial_work_orders: snakeCaseKeys(this.initialWorkOrders)
      }
      document.getElementById('order_json_params').value = JSON.stringify(extraData)
    },

    setupCustomerSelect() {
      let $customerSelect = $('#order_customer_id')
      let selectedCustomer = $customerSelect.data('selected')
      this.configCustomerSelect($customerSelect, selectedCustomer)
      this.customerSelectize.on('item_add',  this.setCustomer)
    },

    reorderLineItems(_) {
      jQuery.ajax({
        method: 'POST',
        url: `/orders/${this.id}/reorder_line_items.json`,
        data: {
          line_item_ids: this.lineItems.map(item => item.id)
        }
      })
    },

    blockSubmit() {
      this.saveBlocked = true
    },

    checkSubmit() {
      if (this.saveBlocked) {
        return false
      } else {
        return true
      }
    }
  }
})
